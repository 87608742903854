import { LinearProgress } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import GoBackButton from "components/GoBackButton"
import { useHistory, useParams } from "react-router-dom"
import { ClientUserDetailsModel, UserRoleModel } from "services/openapi"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import { getUser } from "./apiRequests"
import { useTranslation } from "react-i18next"
import StyledHeader from "./styled/StyledHeader"
import FeelingAggregateCard from "./FeelingAggregateCard"
import { CareOrgUserContext } from "contexts/careOrgUserContext"
import UserDayListing from "components/UserDayListing"
import CareOrganizationMobileViewBase from "components/CareOrganizationMobileViewBase"

const CareOrganizationUserClientDetails = () => {
  const history = useHistory()
  const { id, clientId } = useParams<{ id: string; clientId: string }>()
  const { ErrorSnackbar, handleError } = useApiRequestError()
  const { state, dispatch } = useContext(CareOrgUserContext)
  const { t } = useTranslation()
  const [isLoadingUser, setLoadingUser] = useState<boolean>(true)

  useEffect(
    function fetchAggregated() {
      const onClientsFetchedSuccess = (client: ClientUserDetailsModel) =>
        dispatch({ type: "updateCurrentClient", client: client })

      getUser(clientId, setLoadingUser, onClientsFetchedSuccess, handleError)
    },
    [clientId, dispatch, handleError]
  )

  const client = state.currentClient || {
    id: "",
    firstName: "",
    lastName: "",
    clientGroupId: 0,
    role: UserRoleModel.CLIENT,
  }

  const moveToDetails = (date: string) => {
    history.push(`/care-org-user/family/${id}/client/${clientId}/${date}`)
  }

  return (
    <CareOrganizationMobileViewBase>
      {isLoadingUser ? (
        <LinearProgress />
      ) : (
        <>
          <StyledHeader variant="h1">
            <strong>{t(`care-org-mobile-view.client-details.client-group-${client.clientGroupId}`)} </strong>
            <br></br>
            <strong>
              {client.firstName} {client.lastName}
            </strong>
          </StyledHeader>
          <FeelingAggregateCard clientId={clientId} />
          {state.selectedTimespan ? (
            <UserDayListing clientId={clientId} timespan={state.selectedTimespan} dayClicked={moveToDetails} />
          ) : (
            <></>
          )}
        </>
      )}
      <GoBackButton url={`/care-org-user/family/${id}`} />
      <ErrorSnackbar />
    </CareOrganizationMobileViewBase>
  )
}
export default CareOrganizationUserClientDetails
