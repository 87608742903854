import {
  FeelingModel,
  FeelingService,
  FeelingTargetModel,
  UserFeelingListModel,
  UserFeelingModel,
} from "services/openapi"

export const getFeelingOptionsForClientGroup = async (
  groupId: number | undefined,
  setLoading: (isLoading: boolean) => void,
  onSuccess: (feelings: FeelingModel[]) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    setLoading(true)
    const feelingOptions = await FeelingService.feelingGetFeelingsForClientGroup(groupId)
    onSuccess(feelingOptions)
    setLoading(false)
    return feelingOptions
  } catch (error) {
    onError(error, "youth-view.snackbar.fetch-feeling-options-error")
    setLoading(false)
  }
}

export const getFeelingTargetsForClientGroup = async (
  groupId: number | undefined,
  setLoading: (isLoading: boolean) => void,
  onSuccess: (feelings: FeelingTargetModel[]) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    setLoading(true)
    const targetOptions = await FeelingService.feelingGetFeelingTargetsForClientGroup(groupId)
    onSuccess(targetOptions)
    setLoading(false)
    return targetOptions
  } catch (error) {
    onError(error, "youth-view.snackbar.fetch-feeling-targets-error")
    setLoading(false)
  }
}

export const getFeelingUser = async (
  userId: string,
  from: string,
  to: string,
  setLoading: (isLoading: boolean) => void,
  onSuccess: (feelings: UserFeelingListModel[]) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    setLoading(true)
    const feelingResponse = await FeelingService.feelingGetForUser(userId, from, to)
    onSuccess(feelingResponse)
  } catch (error) {
    onError(error, "care-org-mobile-view.client-details.feeling-fetch-error")
  }
  setLoading(false)
}

export const getFeelingsForDate = async (
  userId: string,
  date: string,
  setLoading: (isLoading: boolean) => void,
  onSuccess: (feelingContainer: UserFeelingModel) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    setLoading(true)
    var response = await FeelingService.feelingGetForUserSingleDay(userId, date)
    onSuccess(response)
  } catch (error) {
    onError(error, "Could not fetch feelings for the date")
  }
  setLoading(false)
}
