import { Typography } from "@mui/material"
import { Box } from "@mui/system"
import RoundEndingViewLinkButton from "components/RoundEndingViewLinkButton"
import { userContext } from "contexts/userContext"
import i18n from "i18n"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import ClientGroupId from "utils/clientGroupId"

type LinkHolder = {
  url: string
  name: string
}

type ClientGroupHolder = {
  youth: LinkHolder[]
  adults: LinkHolder[]
  elders: LinkHolder[]
}

const LinkSection = () => {
  const { t } = useTranslation()
  const { user } = useContext(userContext)

  const helpLinks: { [name: string]: ClientGroupHolder } = {
    fi: {
      youth: [
        { url: "https://apuu.fi", name: "Kun olo on turvaton" },
        {
          url: "https://www.nuortennetti.fi/apua-ja-tukea/lasten-ja-nuorten-puhelin/lasten-ja-nuorten-chat/",
          name: "MLL lasten ja nuorten chat",
        },
        { url: "https://apuaeroon.fi/info/chat_auttaa/", name: "Vanhemmat eroavat" },
        { url: "https://sekasin247.fi", name: "Sekasin discord 24/7" },
        { url: "https://mieli.fi/tukea-ja-apua/kriisipuhelin", name: "Ahdistaa ja paha olla" },
        { url: "https://www.mielenterveystalo.fi/nuoret/", name: "Mielenterveystalo nuorille" },
        { url: "https://nettiturvakoti.fi", name: "Nettiturvakoti" },
        { url: "https://syomishairioliitto.fi/tukea-ja-toimintaa#tukipuhelin-ja-chat", name: "Syömishäiriöliitto " },
        { url: "https://kpsaatio.fi/apua-arkeen-viittomakielella/auttava-linja/", name: "Kuurojen palvelusäätiön auttava linja" },
        { url: "https://lasinenlapsuus.fi/nuorelle", name: "Vanhemmat käyttävät päihteitä\n (12-25 v.)" },
        { url: "https://www.kela.fi/yhteistyokumppanit-kuntoutuspalvelut-palveluntuottajille-palvelukuvaukset-taydentava-ohje-nuotti-valmennus", name: "Nuotti-valmennus 16-29 v. nuorille" },
        { url: "https://www.zekki.fi/", name: "Zekin avulla voit selvittää, miten sulla menee. 15-25 v. nuorille" }
      ],
      adults: [
        { url: "https://takuusaatio.fi/", name: "Velat ja talous" },
        { url: "https://mieli.fi/tukea-ja-apua/kriisipuhelin/", name: "Mieli ry:n kriisipuhelin 24/7" },
        { url: "https://www.mll.fi/vanhemmille", name: "MLL apua vanhemmuuteen" },
        { url: "https://ensijaturvakotienliitto.fi/apua-sinulle/", name: "Apua vanhemmuuteen" },
        { url: "https://nettiturvakoti.fi", name: "Nettiturvakoti" },
        { url: "https://nollalinja.fi", name: "Nollalinja lähisuhdeväkivallalle" },
        { url: "https://peluuri.fi", name: "Peliongelmia" },
        { url: "https://irtihuumeista.fi", name: "Irti huumeista ry" },
        { url: "https://syomishairioliitto.fi/tukea-ja-toimintaa#tukipuhelin-ja-chat", name: "Syömishäiriöliitto" },
        { url: "https://kpsaatio.fi/apua-arkeen-viittomakielella/auttava-linja/", name: "Kuurojen palvelusäätiön auttava linja" },
        { url: "https://lasinenlapsuus.fi/nuorelle", name: "Vanhemmat käyttävät päihteitä\n (12-25 v.)" },
        { url: "https://omaishoitajat.fi/", name: "Omaishoitajille tukea" }
      ],
      elders: [
        {
          url: "https://vtkl.fi/toiminta/vahvikelinja",
          name: "Vanhustyön keskusliitto / Vahvikelinja, \nma-ke, su, klo 13:00-15:00,\nPuh. 050 3288 588",
        },
        {
          url: "https://seniorsurf.fi/",
          name: "Vanhustyön keskusliitto / Ikädigituki - SeniorSurf \nma ja to klo 10:00 - 12:00\nPuh. 044 7007 101",
        },
        {
          url: "https://www.helsinkimissio.fi/tarvitsetko-apua/senioreille/aamukorva/",
          name: "Aamukorva-puhelinpalvelu\nPuh. 092 312 0210\nKuuntelee joka aamu klo 6:00 - 8:00."
        },
        {
          url: "https://nollalinja.fi/mika-on-vakivaltaa/?gclid=CjwKCAiAyPyQBhB6EiwAFUuaksPrDA1ErjgCngqE8NrvIY_7iD5YZclpdiXfmXUS1j5erEEgVa9D8xoC-FcQAvD_BwE",
          name: "Nollalinja lähisuhdeväkivallalle 24/7 \nPuh. 080 005 005",
        },
        {
          url: "https://mieli.fi/tukea-ja-apua/kriisipuhelin/",
          name: "Mieli ry Valtakunnallinen kriisipuhelin 24/7 \nPuh. 092 525 0111",
        },
        {
          url: "https://kpsaatio.fi/apua-arkeen-viittomakielella/auttava-linja/",
          name: "Kuurojen palvelusäätiön auttava linja"
        },
        {
          url: "https://suvantory.fi/",
          name: "Suvantolinja, apua erilasiin kaltoinkohtelu ja väkivaltatilanteisiin,\nke klo 10 - 13,\nPuh. 080 0067 76"
        },
        { url: "https://www.kaikkisyovasta.fi/", name: "Tietopankki syövästä" }
      ],
    },
    en: {
      youth: [
        { url: "https://apuaeroon.fi/en/info/chat_auttaa/", name: "Parents are divorcing" },
        { url: "https://mieli.fi/en/support-and-help/crisis-helpline/", name: "Anxious and the mind is down" },
        { url: "https://nettiturvakoti.fi/en_onlineshelter/", name: "Online shelter" },
        { url: "https://syomishairioliitto.fi/in-english", name: "The Eating Disorder Association of Finland" },
        { url: "https://kpsaatio.fi/homepage/the-service-foundation-for-the-deaf/", name: "The Service Foundation for the Deaf helpline" },
        { url: "https://lasinenlapsuus.fi/nuorelle", name: "Parents use drugs\n (for ages 12-25)" }
      ],
      adults: [
        { url: "https://mieli.fi/en/support-and-help/crisis-helpline/", name: "Mieli ry, Crisis Helpline" },
        { url: "https://www.mll.fi/en/", name: "MLL Support for parenting." },
        { url: "https://ensijaturvakotienliitto.fi/en", name: "Help for parenting" },
        { url: "https://nettiturvakoti.fi/en_onlineshelter/", name: "Online shelter" },
        { url: "https://nollalinja.fi/en/", name: "Zeroline violence in a close relationship" },
        { url: "https://www.peluuri.fi/en", name: "Gaming problems" },
        { url: "https://syomishairioliitto.fi/in-english", name: "The Eating Disorder Association of Finland" },
        { url: "https://kpsaatio.fi/homepage/the-service-foundation-for-the-deaf/", name: "The Service Foundation for the Deaf helpline" },
        { url: "https://omaishoitajat.fi/in-english/", name: "Carers Finland" }
      ],
      elders: []
    },
    se: {
      youth: [],
      adults: [],
      elders: [
        {
          url: "https://seniorsurf.fi/svenska/",
          name: "Centralförbundet för de gamlas väl ry / SeniorSurf"
        },
        {
          url: "https://nollalinja.fi/mika-on-vakivaltaa/?gclid=CjwKCAiAyPyQBhB6EiwAFUuaksPrDA1ErjgCngqE8NrvIY_7iD5YZclpdiXfmXUS1j5erEEgVa9D8xoC-FcQAvD_BwE",
          name: "Nollinjen mot våld i nära relationer och våld mot kvinnor 24/7\nPuh. 080 005 005",
        },
        {
          url: "https://mieli.fi/tukea-ja-apua/kriisipuhelin/",
          name: "Psykisk Hälsa Finland rf\nma, ke klo 16:00 - 20:00, ti, to ja pe klo 9:00 – 13:00,\nPuh. 09 2525 0112",
        },
        {
          url: "https://www.alltomcancer.fi/",
          name: "Informationsbank om cancer"
        }
      ],
    },
    de: {
      youth: [],
      adults: [],
      elders: [],
    }
  }

  const languageVersion: ClientGroupHolder = helpLinks[i18n.language]

  let links =
    user?.clientGroupId === ClientGroupId.ADULTS
      ? languageVersion.adults
      : user?.clientGroupId === 2
        ? languageVersion.elders
        : languageVersion.youth

  const urlButtons = links.map((service, i) => {
    return <RoundEndingViewLinkButton key={i} url={service.url} text={service.name} icon={<></>} />
  })

  return (
    <>
      {links.length > 0 && (
        <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", mt: 2 }}>
          <Typography
            sx={{ fontFamily: "SofiaProBold", margin: 1 }}
            aria-describedby={t("aria-labels.open-in-new-window")}
          >
            {t("ending-view.links-title")}
          </Typography>
          {urlButtons}
        </Box>
      )}
    </>
  )
}

export default LinkSection
