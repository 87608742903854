import { createTheme } from "@mui/material/styles"

export const mobileTheme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#CAE6F4",
      dark: "#374a67",
      light: "#4F5971",
    },
    warning: {
      main: "#D1559A",
    },
    text: {
      primary: "rgba(21,21,21,1)",
    },
    background: {
      default: "#FCFCFC",
    },
    feelingColors: {
      main: "#fff",
      glad: "#ff99c7",
      happy: "#ef48b3",
      energetic: "#f9de15",
      hopeful: "#f55b32",
      confident: "#bf5b37",
      calm: "#0093c6",
      content: "#ff99c7",
      interested: "#6ebf4c",
      amazed: "#089faf",
      ok: "#ef3554",
      sleepy: "#0093c6",
      crying: "#5555f9",
      sad: "#68cef4",
      disgusted: "#bab62f",
      irritated: "#06b280",
      anxious: "#b792ff",
      lonely: "#6193f7",
      angry: "#895cf7",
      hopeless: "#c954ef",
      fearful: "#c954ef",
      worried: "#56b1e8",
      empowered: "#ffac33",
    },
  },
  margins: {
    globalX: 4,
  },
  typography: {
    fontFamily: "SofiaProLight",
  },
  accentColors: {
    pink: "#EF48B3",
  },
  feelingColors: {
    glad: "#ff99c7",
    happy: "#ef48b3",
    energetic: "#f9de15",
    excited: "#ffac33",
    hopeful: "#f55b32",
    confident: "#bf5b37",
    serene: "#0093c6",
    content: "#0093c6",
    interested: "#6ebf4c",
    amazed: "#089faf",
    ok: "#0093c6",
    sleepy: "#a7a7aa",
    crying: "#5555f9",
    sad: "#68cef4",
    disgusted: "#bab62f",
    irritated: "#06b280",
    anxious: "#b792ff",
    lonely: "#6193f7",
    angry: "#895cf7",
    hopeless: "#c954ef",
    fearful: "#56b1e8",
    worried: "#0093c6",
    empowered: "#ffac33",
    social: "#03fcd6",
    bored: "#06b280",
    tired: "#a7a7aa",
    frustratedOrAngry: "#895cf7",
    calm: "#ef3554",
    safe: "#0093c6",
    spry: "#f9de15",
  },
  diaryView: {
    contentWidth: "95vw",
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          // Make placeholder texts of all inputs dark
          "& input::placeholder, & textarea::placeholder": {
            color: "rgba(21,21,21,1)",
            opacity: 1,
          },
        },
      },
    },
  },
})

declare module "@mui/material/styles" {
  interface Theme {
    /*
     * Custom theming variables here
     * status: {
     *  danger: string
     * }
     */
    margins?: {
      globalX?: number
    }
    accentColors?: {
      pink: string
    }
    feelingColors?: {
      glad: string
      happy: string
      energetic: string
      excited: string
      hopeful: string
      confident: string
      serene: string
      content: string
      interested: string
      amazed: string
      ok: string
      sleepy: string
      crying: string
      sad: string
      disgusted: string
      irritated: string
      anxious: string
      lonely: string
      angry: string
      hopeless: string
      fearful: string
      worried: string
      empowered: string
      social: string
      bored: string
      tired: string
      frustratedOrAngry: string
      calm: string
      safe: string
      spry: string
    }
    diaryView?: {
      contentWidth: string
    }
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    /*
     * And here as well
     * status?: {
     *  danger?: string
     * }
     */
    margins?: {
      globalX?: number
    }
    accentColors?: {
      pink: string
    }
    feelingColors?: {
      glad: string
      happy: string
      energetic: string
      excited: string
      hopeful: string
      confident: string
      serene: string
      content: string
      interested: string
      amazed: string
      ok: string
      sleepy: string
      crying: string
      sad: string
      disgusted: string
      irritated: string
      anxious: string
      lonely: string
      angry: string
      hopeless: string
      fearful: string
      worried: string
      empowered: string
      social: string
      bored: string
      tired: string
      frustratedOrAngry: string
      calm: string
      safe: string
      spry: string
    }
    diaryView?: {
      contentWidth: string
    }
  }

  interface Palette {
    feelingColors: Palette["primary"]
  }

  interface PaletteOptions {
    feelingColors: PaletteOptions["primary"]
  }

  interface SimplePaletteColorOptions {
    glad?: string
    happy?: string
    energetic?: string
    hopeful?: string
    confident?: string
    calm?: string
    content?: string
    interested?: string
    amazed?: string
    ok?: string
    sleepy?: string
    crying?: string
    sad?: string
    disgusted?: string
    irritated?: string
    anxious?: string
    lonely?: string
    angry?: string
    hopeless?: string
    fearful?: string
    worried?: string
    empowered?: string
  }
}
