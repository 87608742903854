import { Box, Typography } from "@mui/material"
import useMountEffect from "utils/hooks/useMountEffect"
import { getFeelingOptionsForClientGroup } from "utils/apiRequests/fetchFeelings"
import { FeelingModel, GroupedFeelings } from "services/openapi"
import { useState } from "react"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import { useTranslation } from "react-i18next"

type FeelingListProps = {
  feelings: GroupedFeelings[]
}

const FeelingList = ({ feelings }: FeelingListProps) => {
  const [feelingOptions, setFeelingOptions] = useState<FeelingModel[]>([])
  const [isLoading, setLoading] = useState<boolean>(true)
  const { handleError } = useApiRequestError()
  const { t } = useTranslation()

  var total = 0
  for (var i in feelings) {
    total += feelings[i].sum > 0 ? feelings[i].sum : feelings[i].total
  }

  useMountEffect(async function fetchData() {
    await getFeelingOptionsForClientGroup(undefined, setLoading, setFeelingOptions, handleError)
  })

  const feelingList = feelings?.map((feeling) => {
    let feelingDetails
    const amount = total === 0 ? "" : `${(((feeling.sum > 0 ? feeling.sum : feeling.total) / total) * 100).toFixed(0)}%`

    if (feelingOptions !== undefined) {
      feelingDetails = feelingOptions.find((x) => x.id === feeling.feelingId)
    }

    return (
      <Box key={feeling.feelingId.toString()} sx={{ m: 1, width: "270px" }}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <img
            src={`${process.env.PUBLIC_URL}/feelingIcons/${feelingDetails?.imageLocation}`}
            width={38}
            alt={`${feelingDetails?.imageLocation}`}
            aria-hidden="true"
          />
          <Typography sx={{ pl: 1 }}>
            {t(`youth-view.feeling-selection.feeling-options.${feelingDetails?.name}`)} {amount}
          </Typography>
        </Box>
      </Box>
    )
  })

  return (
    <Box sx={{ mt: 5, display: "flex", justifyContent: "flex-start", flexWrap: "wrap" }}>
      {isLoading ? <></> : feelingList}
    </Box>
  )
}

export default FeelingList
