import { Box, Card, CardContent, CircularProgress, useTheme } from "@mui/material"
import FeelingDoughnut from "components/FeelingDoughnut"
import { Timespan, TimeSpanSelectionButtons } from "components/TimeSpanSelectionButtons"
import { CareOrgUserContext } from "contexts/careOrgUserContext"
import { useCallback, useContext, useEffect, useState } from "react"
import FeelingList from "scenes/MobileFeelingHistory/FeelingList"
import { GroupedFeelings } from "services/openapi"
import { matchFeelingIdWithColor } from "utils/functions/matchFeelingIdWithColor"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import { getAggregated } from "./apiRequests"
import { useHistory, useLocation } from "react-router-dom"

type FeelingAggregateCardProps = {
  clientId: string
}

const FeelingAggregateCard = ({ clientId }: FeelingAggregateCardProps) => {
  const theme = useTheme()
  const { handleError } = useApiRequestError()
  const [isLoadingFeelings, setLoadingFeelings] = useState(true)
  const [feelings, setFeelings] = useState<GroupedFeelings[]>([])
  const { state, dispatch } = useContext(CareOrgUserContext)
  const history = useHistory()
  const location = useLocation()

  const colors = feelings.map((feeling) => matchFeelingIdWithColor(feeling.feelingId, theme)!)
  const data = feelings.map((feeling) => (feeling.sum > 0 ? feeling.sum : feeling.total))

  const updateSpan = useCallback(
    (span: Timespan) => {
      dispatch({ type: "updateCurrentTimespan", timespan: span })
      history.push(
        `${location.pathname}${span.name ? "?timespan=" + span.name + "&start=" + span.start + "&end=" + span.end : ""}`
      )
    },
    [dispatch, history, location.pathname]
  )

  useEffect(() => {
    const asyncWrapper = async () => {
      await getAggregated(
        clientId,
        state.selectedTimespan.start,
        state.selectedTimespan.end,
        setLoadingFeelings,
        setFeelings,
        handleError
      )
    }

    asyncWrapper()
  }, [clientId, handleError, state.selectedTimespan.end, state.selectedTimespan.start])

  return (
    <Card
      elevation={4}
      sx={{
        borderRadius: 3,
        width: theme.diaryView?.contentWidth,
        mx: "auto",
      }}
    >
      <CardContent>
        <TimeSpanSelectionButtons onSpanSelected={updateSpan} />
        <Box sx={{ mx: 2, my: 2 }}>
          {isLoadingFeelings ? (
            <CircularProgress size={"100%"} sx={{ display: "block" }} />
          ) : (
            <>
              <FeelingDoughnut chartColors={colors} chartData={data} showPercentaces={true} />
              <FeelingList feelings={feelings} />
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  )
}
export default FeelingAggregateCard
