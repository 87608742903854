import { Box, Card, CardContent, Typography, useTheme } from "@mui/material"
import FeelingDoughnut from "components/FeelingDoughnut"
import FeelingList from "./FeelingList"
import { matchFeelingIdWithColor } from "utils/functions/matchFeelingIdWithColor"
import { useTranslation } from "react-i18next"
import { GroupedFeelings } from "services/openapi"

type FeelingDisplayProps = {
  userFeelings: GroupedFeelings[]
}

const FeelingDisplay = ({ userFeelings }: FeelingDisplayProps) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const colors = userFeelings.map((feeling) => matchFeelingIdWithColor(feeling.feelingId, theme)!)
  const data = userFeelings.map((feeling) => (feeling.sum === 0 ? feeling.total : feeling.sum))

  return (
    <Card sx={{ mt: 2, mb: 2, mx: 2 }}>
      <CardContent>
        <Box>
          {!userFeelings.length && (
            <Typography variant="body1">{t("customer-mobile-view.feeling-history.no-feelings")}</Typography>
          )}
          <FeelingDoughnut chartColors={colors} chartData={data} showPercentaces={true} />
        </Box>
        <FeelingList feelings={userFeelings} />
      </CardContent>
    </Card>
  )
}

export default FeelingDisplay
