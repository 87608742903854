import { useCallback, useContext, useEffect, useState } from "react"
import { Box, CircularProgress } from "@mui/material"
import FeelingDisplay from "./FeelingDisplay"
import { GroupedFeelings } from "services/openapi"
import { userContext } from "contexts/userContext"
import { getAggregated } from "scenes/CareOrganizationUserClientDetails/apiRequests"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import UserDayListing from "components/UserDayListing"
import { useHistory, useLocation } from "react-router-dom"
import { Timespan, TimeSpanSelectionButtons } from "components/TimeSpanSelectionButtons"
import { timeSpanOptions } from "../../utils/timeSpanUtils"

const HistorySection = () => {
  const history = useHistory()
  const location = useLocation()
  const { user } = useContext(userContext)
  const [feelings, setFeelings] = useState<GroupedFeelings[]>([])
  const [selectedSpan, setSelectedSpan] = useState<Timespan>(timeSpanOptions.week)
  const { handleError } = useApiRequestError()
  const [isLoadingFeelings, setLoadingFeelings] = useState<boolean>(true)

  const updateSpan = useCallback(
    (span: Timespan) => {
      setSelectedSpan(span)
      history.push(
        `${location.pathname}${span.name ? "?timespan=" + span.name + "&start=" + span.start + "&end=" + span.end : ""}`
      )
    },
    [history, location.pathname]
  )

  const dayClicked = (date: string) => {
    history.push(`/client/feeling-history/${date}`)
  }

  useEffect(() => {
    const asyncWrapper = async () => {
      await getAggregated(
        user?.id ?? "",
        selectedSpan.start,
        selectedSpan.end,
        setLoadingFeelings,
        setFeelings,
        handleError
      )
    }

    asyncWrapper()
  }, [handleError, selectedSpan.start, selectedSpan.end, user?.id])

  return (
    <Box
      sx={{
        marginX: "auto",
      }}
    >
      <TimeSpanSelectionButtons onSpanSelected={updateSpan} />
      {isLoadingFeelings ? (
        <CircularProgress size={"100%"} sx={{ display: "block" }} />
      ) : (
        <>
          <FeelingDisplay userFeelings={feelings} />
          {selectedSpan && user?.id ? (
            <UserDayListing clientId={user?.id ?? ""} timespan={selectedSpan} dayClicked={dayClicked} />
          ) : (
            <></>
          )}
        </>
      )}
    </Box>
  )
}

export default HistorySection
